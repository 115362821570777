import React, { FC } from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Link } from 'gatsby';
import { Grid, Typography, Container, Card, CardContent, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import certTradicion from './assets/images/certtradicion.svg';
import certPropiedad from './assets/images/certPropiedad.svg';
import detekta from './assets/images/detekta.svg';
import certExistencia from './assets/images/certExistencia.svg';
import historialVeh from './assets/images/historialVeh.svg';

const documentsContent = [
  {
    title: 'Detekta',
    description: 'Analizamos el Certificado de Tradición y Libertad mediante procesos de inteligencia artificial, generando un diagnóstico de la situación jurídica del inmueble.',
    link:'https://ingenio.kushki.com/detekta',
    img: detekta
  },{
    title: 'Certificado de Existencia y Representación Legal',
    description: 'En asociación con la Cámara de Comercio de Bogotá,  ofrecemos el documento que acredita la inscripción del contrato social, las reformas y los nombramientos de administradores y representantes legales.',
    img: certExistencia
  }
]

const comercialesDocuments : React.FC = () => {
  return (
    <div>
      <Container>
        <Grid container lg={12} md={12} sm={12}
          spacing={{ xs: 2, md: 6 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          columns={12}
          py={"2rem"}
          mt={"4rem"}
        >
          <Grid item lg={12} md={12} sm={12} >
            <Typography color="text.primary" variant="h2" textAlign={"center"} sx={{ mb: '2rem' }}>
              Documentos Habilitados
            </Typography>
            <Typography color="text.dark" variant="body1" textAlign={"center"}>
              Ofrecemos acceso directo a la fuente para la <strong>consulta, compra y descarga</strong> de diferentes documentos.
            </Typography>
            <br/>
            <Typography color="text.dark" variant="body1" textAlign={"center"}>
              Contamos con diferentes tecnologías para conectar a las entidades con el cliente final, dándole a escoger al usuario la solución que <strong>mejor se acomode a sus necesidades y capacidades</strong>.
            </Typography>
          </Grid>
          <Grid 
            container lg={12} md={12} sm={12} xs={12}
            spacing={{ xs: 'none', sm: 'none', md: 3 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {
              documentsContent.map((item, index) => {
                return (
                  <Box sx={{ px:'1rem', overflow:'hidden' }}>
                    <Box sx={{position: 'relative', left: {xs: '6rem', md:'17rem'}, bottom: {xs: '-2.5rem', md:'-4rem'}, zIndex: "1" }}>
                      <img src={item.img} alt={item.title} />
                    </Box>
                    <Card sx={{height: { sm:'260px', md:'280px' }, width:{sm: '300px', md: '350px' }, p: '1rem', borderRadius: '15px' }}>
                      <Box sx={{width:'90%', position: 'relative', top:{xs:'2rem'}, px:'1rem',  mb: '1.5rem' }}>
                        <Typography color="text.alternate" variant="h5" textAlign={{md:'left', xs:'center'}}>
                          {item.title}
                        </Typography>
                      </Box>
                      <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                        <Typography color="text.dark" variant="caption" sx={{ mb: '0.5rem' }} textAlign={{md:'left', xs:'center'}}>
                          {item.description}
                          
                        </Typography>
                        <Box  sx={{ mt: '0.8rem' }}>
                          { item.link ? <OutboundLink href={item.link} color='primary' target="blank"> Ver más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </OutboundLink> : <Typography color="text.dark" variant="body2"> Próximamente </Typography> }
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                )
              })
            }
            <Box sx={{ px:'1rem', overflow:'hidden' }}>
              <Box sx={{position: 'relative', left: {xs: '6rem', md:'17rem'}, bottom: {xs: '-2.5rem', md:'-4rem'}, zIndex: "1" }}>
                <img src={historialVeh} />
              </Box>
              <Card sx={{height: { sm:'210px', md:'280px' }, width:{sm: '300px', md: '350px' }, p: '1rem', borderRadius: '15px' }}>
                <Box sx={{width:'90%', position: 'relative', top:{xs:'2rem'}, px:'1rem',  mb: '1.5rem' }}>
                  <Typography color="text.alternate" variant="h5" textAlign={{md:'left', xs:'center'}}>
                    Histórico Vehicular
                  </Typography>
                </Box>
                <CardContent sx={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent:'center'}}>
                  <Typography color="text.dark" variant="caption" sx={{ mb: '0.5rem' }} textAlign={{md:'left', xs:'center'}}>
                    En unión con RUNT desarrollamos el documento detallado sobre la historia del vehículo.
                  </Typography>
                  <Box  sx={{ mt: '0.8rem' }}>
                    <OutboundLink href="https://www.historialvehicular.co/" color='primary' target="blank"> Ver más <ArrowForwardIcon color='primary' fontSize='small'  sx={{ ml: '1rem' }}/> </OutboundLink>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default comercialesDocuments;